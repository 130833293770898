












































// Core
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Debounce from '@/decorators/Debounce'

// Mixins

// Store

// Types
import { UsersActions } from '@/store/users/types'

// Interfaces
import { Meta, Paginate, RequestParams } from '@/store/interfaces'
import { NaturalPerson } from '@/store/users/interfaces'

// Modules
const NSUsers = namespace('usersModule')

@Component({
  name: 'DialogChoosePerson',

  components: {
    'v-scroll-list': () => import('@components/ScrollList.vue'),
  },
})
export default class DialogChoosePersonComponent extends Vue {
  @PropSync('visible', Boolean) private _visible!: boolean

  @Prop(Number) private projectId!: number

  @Prop(Number) private legalPersonId!: number

  @Prop(Function) private connectNaturalPerson!: (naturalPerson: NaturalPerson) => void

  @NSUsers.Action(UsersActions.A_FETCH_FILTERED_NATURAL_PERSONS)
  private fetchNaturalPersons!: (params?: RequestParams) => Promise<Paginate<NaturalPerson>>


  private search = ''

  private naturalPersons: NaturalPerson[] = []
  private meta: Meta | null = null

  private loading: boolean = false

  created() {
    this.fetchNaturalPersons({ projectId: this.projectId, legalPersonId: 0, count: 5 }).then(naturalPersons => {
      this.naturalPersons = naturalPersons.data
      this.meta = naturalPersons.meta
    })
  }

  private handleConnectNaturalPerson (naturalPerson: NaturalPerson) {
    this.connectNaturalPerson(naturalPerson)
    this.naturalPersons = this.naturalPersons.filter(n => n.id !== naturalPerson.id)
  }

  @Debounce(1000)
  private handleChangeSearch() {
    if (this.search.length >= 3 || this.search.length === 0) {
      this.loading = true
      this.fetchNaturalPersons({ search: this.search, projectId: this.projectId, legalPersonId: 0, count: 5 }).then(naturalPersons => {
        this.naturalPersons = naturalPersons.data
        this.meta = naturalPersons.meta
      }).finally(() => this.loading = false)
    }
  }

  private loadMore() {
    if (this.meta && this.meta.lastPage > this.meta.currentPage) {
      const page = (this.meta.currentPage += 1)

      this.fetchNaturalPersons({ search: this.search, projectId: this.projectId, count: 5, page }).then(
        naturalPersons => {
          this.naturalPersons.push(...naturalPersons.data)
          this.meta = naturalPersons.meta
        },
      )
    }
  }
}
